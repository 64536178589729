import { PageProps } from "gatsby";
import React from "react";

interface HomePageProps {}

const HomePage: React.FC<PageProps<HomePageProps>> = ({}) => {
  return null;
};

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default HomePage;
